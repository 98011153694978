// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: "https://demo.canaldenuncia.app/backend",
  baseAvatar: "https://demo.canaldenuncia.app/assets/avatar/",
  base: "https://demo.canaldenuncia.app",
  secretKey: 'def00000f3f99257d33857cf24088d239926db31adb6453e1e27548d5cd2bfebc8177f4fd7c2060d8d0956f65204ab8ee1c9be29b4ed01a3e8020cf7ee792721bc7607b5'
};
//integracion.becompliance.tech
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

